import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import '../css/typography.css';

// import './layout.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans JP'; 
    letter-spacing: 0.05em;
    overflow-x: hidden;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        meta={[{ name: "apple-itunes-app", content: "app-id=1546175303" }]}
      />
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <GlobalStyle />
      <main>{children}</main>
      {/* <footer style={{
        marginTop: `2rem`
      }}>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
